import { debounce } from 'lodash'
import { useClickOutside } from 'stimulus-use'
import BaseController from './base_controller'

export default class FetchAutocompleteController extends BaseController {
  static targets = ['input', 'hiddenInput']

  static values = {
    url: String,
    urlParams: Object,
    singleFetch: { type: Boolean, default: false }
  }

  static outlets = ['fetch-autocomplete-results']

  connect () {
    super.connect()
    useClickOutside(this)

    // debounce isn't necessary if the request responds quickly, set 100ms here as a baseline
    // if requests start taking longer, consider:
    // - setting the debounce time with a Stimulus valueTarget
    // - adding a requestIndex (or requestTerm) parameter, and checking against it before setting the results
    this.autocomplete = debounce(this.autocomplete, 100)

    this.floatingResults = document.getElementById('autocomplete_results')
  }

  autocomplete () {
    if (this.singleFetchValue && this.resultsAreVisible()) {
      this.localFilterResults()
    } else {
      // if setting autocomplete result into hidden input, clear it before each new autocomplete fetch request
      if (this.hasHiddenInputTarget) {
        this.updateHiddenInputTargetValue('')
      }

      this.fetchResults()
    }
  }

  fetchResults () {
    fetch(this.buildURL())
      .then(response => response.text())
      .then(data => this.displayResults(data))
      .then(() => {
        if (this.singleFetchValue) this.localFilterResults()
      })
  }

  displayResults (results) {
    this.floatingResults.innerHTML = results

    this.showResults()
  }

  selectResult (event) {
    const result = event.params.result

    if (this.hasHiddenInputTarget) {
      this.updateHiddenInputTargetValue(result.value)

      this.inputTarget.value = result.text
    } else {
      this.updateInputTargetValue(result.value)
    }

    this.hideResults()
  }

  buildURL () {
    const url = new URL(this.urlValue, window.location.href)

    for (const [name, value] of Object.entries(this.urlParamsValue)) {
      url.searchParams.append(name, value)
    }

    url.searchParams.append('search_term', this.inputTarget.value.trim())

    return url.toString()
  }

  updateHiddenInputTargetValue (value) {
    this.hiddenInputTarget.value = value
    this.hiddenInputTarget.dispatchEvent(new Event('change'))
  }

  updateInputTargetValue (value) {
    this.inputTarget.value = value
    this.inputTarget.dispatchEvent(new Event('change'))
  }

  showResults () {
    this.floatingResults.controllers.attachable.attach(this.inputTarget, true)
  }

  hideResults () {
    this.floatingResults.controllers.attachable.detach()
  }

  resultsAreVisible () {
    return this.floatingResults.controllers.attachable.attachedToElement(this.inputTarget)
  }

  localFilterResults () {
    this.fetchAutocompleteResultsController().localFilterResults(this.inputTarget.value)
  }

  fetchAutocompleteResultsController () {
    return this.fetchAutocompleteResultsOutlets[0] // Assume a single set of results
  }
}
