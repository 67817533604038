import BaseController from './base_controller'

// Attach a floating element to another element
export default class AttachableController extends BaseController {
  attach (elementToAttach, matchWidth) {
    this.attachedElement = elementToAttach
    this.matchWidth = matchWidth
    this.element.hidden = false

    this.reposition()
  }

  detach () {
    this.attachedElement = null
    this.element.hidden = true
  }

  // Repositions element next to attachedElement
  reposition () {
    if (!this.attachedElement) return

    const attachedRect = this.attachedElement.getBoundingClientRect()

    this.element.style.top = attachedRect.bottom + 'px'
    this.element.style.left = attachedRect.left + 'px'

    if (this.matchWidth) {
      this.element.style.width = attachedRect.width + 'px'
    }
  }

  attachedToElement (testElement) {
    return this.attachedElement === testElement
  }
}
