import BaseController from './base_controller'

export default class ScrollingCarouselController extends BaseController {
  static targets = ['scrollContainer', 'prevButton', 'nextButton', 'item']

  connect () {
    super.connect()

    this.currentIndex = 0
    this.updateButtonsVisibility()

    this.scrollContainerTarget.addEventListener('scroll', () => this.updateIvarsFromScrollPosition())
  }

  disconnect () {
    this.scrollContainerTarget.removeEventListener('scroll', () => this.updateIvarsFromScrollPosition())
  }

  scrollToNextItem () {
    if (this.currentIndex === this.itemTargets.length - 1) return

    this.currentIndex++
    this.scrollToCurrentItem()
    this.updateButtonsVisibility()
  }

  scrollToPrevItem () {
    if (this.currentIndex === 0) return

    this.currentIndex--
    this.scrollToCurrentItem()
    this.updateButtonsVisibility()
  }

  // Utility Methods

  scrollToCurrentItem () {
    this.itemTargets[this.currentIndex].scrollIntoView({ behavior: 'smooth', block: 'nearest' })
  }

  updateButtonsVisibility () {
    if (this.currentIndex === 0) {
      this.prevButtonTarget.classList.add('button-hidden')
    } else {
      this.prevButtonTarget.classList.remove('button-hidden')
    }

    if (this.currentIndex === this.itemTargets.length - 1) {
      this.nextButtonTarget.classList.add('button-hidden')
    } else {
      this.nextButtonTarget.classList.remove('button-hidden')
    }
  }

  updateIvarsFromScrollPosition () {
    const itemWidth = this.itemTargets[0].offsetWidth
    const scrollPosition = this.scrollContainerTarget.scrollLeft

    this.currentIndex = Math.round(scrollPosition / itemWidth)

    if (this.currentIndex > this.itemTargets.length - 1) {
      this.currentIndex = this.itemTargets.length - 1
    }
    this.updateButtonsVisibility()
  }
}
