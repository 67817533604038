import { debounce } from 'lodash'
import BaseController from './base_controller'
import { useClickOutside } from 'stimulus-use'

export default class MultiSelectController extends BaseController {
  static targets = ['spanInput', 'hiddenInput', 'pillTemplate', 'pillsContainer', 'placeholderText', 'clearResults']

  static values = {
    url: String
  }

  connect () {
    super.connect()
    useClickOutside(this)

    // debounce isn't necessary if the request responds quickly, set 100ms here as a baseline
    // if requests start taking longer, consider:
    // - setting the debounce time with a Stimulus valueTarget
    // - adding a requestIndex (or requestTerm) parameter, and checking against it before setting the results
    this.autocomplete = debounce(this.autocomplete, 100)

    this.floatingResults = document.getElementById('autocomplete_results')

    this.initialValue = this.hiddenInputTarget.value
    this.renderPills()
    this.setElementVisibilities()
  }

  clickOutside (event) {
    if (event.target.closest('#autocomplete_results')) return // Don't close when clicking inside the autocomplete

    this.floatingResults.controllers.attachable.detach()

    if (this.initialValue === this.hiddenInputTarget.value) return // Don't submit if the value didn't change

    this.element.dispatchEvent(new Event('click-outside'))
  }

  // Action to handle an option being selected or deselected
  optionChange () {
    this.renderPills()
    this.floatingResults.controllers.attachable.detach()
    this.spanInputTarget.innerHTML = ''
    this.focusInput()
    this.setElementVisibilities()
  }

  // Action that allows the user to focus into the input when they click anywhere in the pills container
  focusInput () {
    this.spanInputTarget.focus()
  }

  // Action to handle clearing all the results from the input
  clearResults () {
    this.hiddenInputTarget.value = '[]'
    this.optionChange()
  }

  // Action to handle the autocomplete
  autocomplete () {
    fetch(this.buildURL())
      .then(response => response.text())
      .then(data => this.openAutocompleteResults(data))
  }

  // Action to handle removing options via their x button
  removeOption (event) {
    const pill = event.target.closest('.pill')
    const pillValue = pill.dataset.selectValue
    const selectedOptions = JSON.parse(this.hiddenInputTarget.value)

    const idx = selectedOptions.map(obj => obj.selectValue).indexOf(pillValue)
    selectedOptions.splice(idx, 1)
    this.hiddenInputTarget.value = JSON.stringify(selectedOptions)

    this.renderPills()
    this.setElementVisibilities()

    this.setCheckboxStates()
    this.showResults()
  }

  setElementVisibilities () {
    if (JSON.parse(this.hiddenInputTarget.value).length === 0 && this.spanInputTarget.textContent.trim() === '') {
      this.placeholderTextTarget.hidden = false
      this.clearResultsTarget.hidden = true
    } else {
      this.placeholderTextTarget.hidden = true
      this.clearResultsTarget.hidden = false
    }
  }

  // Updates the pills in the pills container.
  renderPills () {
    this.pillsContainerTarget.querySelectorAll('.pill').forEach(pill => pill.remove())

    JSON.parse(this.hiddenInputTarget.value).forEach(option => {
      const pillToAdd = this.pillTemplateTarget.cloneNode(true)
      pillToAdd.removeAttribute('data-multi-select-target')
      pillToAdd.dataset.selectValue = option.selectValue
      pillToAdd.querySelector('[data-ujs-id="pill_text"]').innerHTML = option.text
      pillToAdd.hidden = false
      this.pillsContainerTarget.insertBefore(pillToAdd, this.spanInputTarget)
    })
  }

  buildURL () {
    const url = new URL(this.urlValue, window.location.href)

    url.searchParams.append('search_term', this.spanInputTarget.textContent.trim())

    return url.toString()
  }

  openAutocompleteResults (results) {
    this.floatingResults.innerHTML = results

    this.showResults()

    this.setCheckboxStates()
  }

  showResults () {
    this.floatingResults.controllers.attachable.attach(this.pillsContainerTarget, true)
  }

  // Set the checkboxes within the autocomplete so they have the correct initial state.
  setCheckboxStates () {
    const checkedValues = JSON.parse(this.hiddenInputTarget.value).map(obj => obj.selectValue)
    this.floatingResults.querySelectorAll('[data-ujs-id="multi-select-checkbox"]').forEach(checkbox => {
      checkbox.checked = checkedValues.includes(checkbox.value)
    })
  }
}
